// Skeleton.jsx
import React from 'react';
import './Skeleton.scss';

function Skeleton() {
  return (
    <div className="skeleton">
      {[...Array(6)].map((_, index) => (
        <div className="skeleton-card card" key={index}>
          <div className="skeleton-img card-img"></div>
          <div className="skeleton-text title"></div>
        </div>
      ))}
    </div>
  );
}

export default Skeleton;
